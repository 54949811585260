.map{
	overflow: hidden;
}

.map--svg {
    width: 300%; 
    float: left;
}

.map--svg path{
	fill: #C5DDF6;
	stroke:#FFF;
	stroke-width: 1px;
	transition: fill 0.3s; 
}
.map--svg path:hover{
	fill: #2DA9C5; 
}
.map--svg .is-active path{
	fill: #2DA9C5;
}




