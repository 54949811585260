.mySwiper .swiper-slide img {
  border-radius: 25px; 
  width: 100%;
  height: auto;
  object-fit: cover;
  
}


.mySwiper .swiper-slide:not(.swiper-slide-active) img {
  opacity: 1;
}


.mySwiper .swiper-slide-active img {
  opacity: 1;
}

.title {
  font-family: 'Poppins, sans-serif';
  color: rgb(45, 169, 197);
  text-align: center;
  margin-bottom: 100px;
  margin-top: 4px;
}
.swiper-section {
  position: relative;
 
}

.explore-button-container {
  position: absolute;
  bottom: -20px; 
  left: 50%;
  transform: translateX(-50%);
  z-index: 10
}

.explore-button {
  background-color: #21A2D9; 
  border: none;
  border-radius: 14px; 
  color: white;
  padding: 14px 36px;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: none;
  font-size: 11px;
}


@media (min-width: 768px) {
  .explore-button-container {
    position: absolute;
    bottom: -70px; 
    left: 50%;
    transform: translateX(-50%);
  }

  .explore-button {
    padding: 15px 50px; 
    border-radius: 8px; 
    font-size: 0.8rem; 
    background-color: #21A2D9;
    box-shadow: 0px 4px 8px rgba(33,162, 217, 0.9);
    
  }
  
  .half-background {
    position: relative; /* Nécessaire pour le positionnement absolu de l'après-pseudo-élément */
  }
  
  .half-background::after {
    content: '';
    position: absolute;
    top: 0; /* Commence en haut de la section */
    right: 0; /* Aligné à droite */
    bottom: 0; /* S'étend jusqu'en bas de la section */
    left: 50%; /* Commence à la moitié de la section */
   /* Remplacez par le chemin de votre image */
    background-size: cover; /* S'assure que l'arrière-plan couvre la moitié de la section */
    z-index: -1; /* S'assure que l'arrière-plan reste derrière le contenu */
  }
   

}
.image-blur {
  filter: blur(2px); /* Réglez selon l'intensité de l'effet flou désirée */
}

.swiper-slide img.swiper-zoom-image {
  cursor: zoom-in;
  transition-duration: 300ms;
}
.swiper-slide img.swiper-zoom-image:hover {
  transform: scale(1.1);
}
